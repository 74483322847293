import { useNavigate } from "@remix-run/react";
import { isSSR } from "~/utils/ssr";

export default function NotFound() {
  const navigate = useNavigate();
  return (
    <div className="w-full h-full flex flex-col items-center justify-center bg-pri dark:bg-pri-d border-l border-pri dark:border-pri-d">
      <div className="flex flex-col gap-2">
        <strong className="text-4xl font-semibold">Oops!</strong>
        <p className="text-lg font-semibold text-zinc-500">
          The page you are looking for does not exist.
        </p>
        <div className="mt-5">
          <button
            type="button"
            onClick={() => navigate(-1)}
            className="py-3 px-5 rounded-full bg-acc dark:bg-acc font-bold text-sm text-pri"
          >
            Go back
          </button>
        </div>
      </div>
    </div>
  );
}
